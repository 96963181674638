import axios from 'axios';
import { getServerUrl } from '@/js/gen.js';

export default {

    //get url() {
    //    let url;
    //    if (serverType === 'dev') {
    //        url = g.siteBackend + '/ws/auth.asmx/';
    //    } else if (serverType === 'production') {
    //        url = prod.siteBackend + '/ws/auth.asmx/';
    //    }
    //    console.log(`Server URL: ${url}`);
    //    return url;
    //},
    get url() {
        let serverType = localStorage.serverType || 'dev';
        let url = getServerUrl(serverType) + '/ws/auth.asmx/';
        console.log(`Server URL: ${url}`);
        return url;
    },

    async Auth2(UserID, UserName, Password) {
        try {
            //this.setServerType(ServerType);
            const res = await axios.get(this.url + 'Auth2', {
                params: {
                    UserID: UserID,
                    UserName: UserName,
                    Password: Password,
                    ShopID: ""
                },
            });

            return res.data;
        } catch (error) {
            return 'ERRO:' + error;
        }
    },

    async Auth(UserID, UserName, Password) {
        try {
            //this.setServerType(ServerType);
            const res = await axios.get(this.url + 'Auth', {
                params: {
                    UserID: UserID,
                    UserName: UserName,
                    Password: Password,
                    ShopID: ""
                },
            });
            
            return res.data;
        } catch (error) {
            return 'ERRO:' + error;
        }
    },
    async IsAuth() {
        try {
            const res = await axios.get(this.url + 'IsAuth', {
                params: {
                    UserKEY: localStorage.k
                },
            });
          
            return res.data;
        } catch (error) {
            return false;
        }
    }

}
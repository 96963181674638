import { createRouter, createWebHashHistory } from 'vue-router';
//createWebHistory

//import listaTabelas from './components/listaTabelas.vue';
//import itensCoeficientes from './components/itensCoeficientes.vue';
//import pdfPage from './components/pdfPage.vue';
//import xmlPage from './components/xmlPage.vue';
//import codeEditor from './components/codeEditor.vue'
//import listaLojas from './components/listaLojas.vue';
//import Tabelas from './components/tabelas.vue';
//import Clientes from './components/clientes.vue';
//import Lojas from './components/lojas.vue';
//import Home from './components/home.vue';
//import confGerais from './components/confGerais.vue';
//import listaUsuarios from './components/listaUsuarios.vue';
//import Usuarios from './components/usuarios.vue';
//import Login from './components/login.vue';
//import TesteFoccoLoja from './components/testeFoccoLoja.vue';
//import teste from './components/teste.vue';


//var vpCatalogoCaracteristicas = httpVueLoader('templates/vpCatalogoCaracteristicas.vue');

//const routes = [
//    { path: '/', component: Login },
//    { path: '/testeFoccoLoja', name: 'testeFoccoLoja', component: TesteFoccoLoja },
//    { path: '/home', name:'home', component: Home },
//    { path: '/listaTabelas', name: 'listaTabelas', component: listaTabelas },
//    { path: '/itensCoeficientes', name: 'itensCoeficientes', component: itensCoeficientes },
//    { path: '/consultaDados', name: 'pdfPage', component: pdfPage },
//    { path: '/xmlfocco', name: 'xmlPage', component: xmlPage },
//    { path: '/codeeditor', name: 'codeEditor', component: codeEditor },
//    { path: '/tabelas',name:'tabelas', component: Tabelas , props: true},
//    { path: '/clientes', name: 'clientes', component: Clientes },
//    { path: '/lojas', name: 'lojas', component: Lojas },
//    { path: '/listaLojas', name: 'listaLojas', component: listaLojas },
//    { path: '/confGerais', name: 'confGerais', component: confGerais },
//    { path: '/usuarios', name: 'usuarios', component: Usuarios },
//    { path: '/listaUsuarios', name: 'listaUsuarios', component: listaUsuarios },
//    { path: '/teste', name: 'teste', component: teste },
//    //{
//    //    path: '/:pathMatch(.*)*', component: () =>
//    //        import('./components/PageNotFound')
//    //}
//];

const routes = [
    { path: '/', component: () => import('./components/login.vue') },
    { path: '/testeFoccoLoja', name: 'testeFoccoLoja', component: () => import('./components/testeFoccoLoja.vue') },
    { path: '/home', name: 'home', component: () => import('./components/home.vue') },
    { path: '/listaTabelas', name: 'listaTabelas', component: () => import('./components/listaTabelas.vue') },
    { path: '/itensCoeficientes', name: 'itensCoeficientes', component: () => import('./components/itensCoeficientes.vue') },
    { path: '/consultaDados', name: 'consultaDados', component: () => import('./components/consultaDados.vue') },
    { path: '/xmlfocco', name: 'xmlPage', component: () => import('./components/xmlPage.vue') },
    { path: '/codeeditor', name: 'codeEditor', component: () => import('./components/codeEditor.vue') },
    { path: '/tabelas', name: 'tabelas', component: () => import('./components/tabelas.vue'), props: true },
    { path: '/clientes', name: 'clientes', component: () => import('./components/clientes.vue') },
    { path: '/lojas', name: 'lojas', component: () => import('./components/lojas.vue') },
    { path: '/listaLojas', name: 'listaLojas', component: () => import('./components/listaLojas.vue') },
    { path: '/confGerais', name: 'confGerais', component: () => import('./components/confGerais.vue') },
    { path: '/usuarios', name: 'usuarios', component: () => import('./components/usuarios.vue') },
    { path: '/listaUsuarios', name: 'listaUsuarios', component: () => import('./components/listaUsuarios.vue') },
    { path: '/teste', name: 'teste', component: () => import('./components/teste.vue') },
    { path: '/foccoerp', name: 'foccoerp', component: () => import('./components/foccoerp.vue') },
    { path: '/:pathMatch(.*)*', component: () => import('./components/PageNotFound')
        }
];



const router = createRouter({
    history: createWebHashHistory(process.env.BASE_URL),
    routes
});
//history: createWebHistory(process.env.BASE_URL),

//router.beforeEach((to, from, next) => {
//    const blockedRoutes = ['foccoerp', 'consultaDados'];
//    if (blockedRoutes.includes(to.name)) {
//        if (localStorage.userPerm !== '999') {
//            next('/not-authorized');
//        } else {
//            next();
//        }
//    } else {
//        next();
//    }
//});

export default router;

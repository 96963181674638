export default {
    getUrlParameter: function (sParam) {
        var sPageURL = window.location.href.split("?")[1];
        if (sPageURL != null) {
            var sURLVariables = sPageURL.split('&'),
                sParameterName,
                i;

            for (i = 0; i < sURLVariables.length; i++) {
                sParameterName = sURLVariables[i].split('=');

                if (sParameterName[0] === sParam) {
                    return sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
                }
            }
        }
        else {
            return '';
        }
    },
    ordenaArrayNumeros: function (arraystring, separador) {
        if (arraystring.indexOf(";") > -1) {
            arraystring = arraystring.split(separador);
            arraystring.sort(function (a, b) { return a - b; });
            arraystring = arraystring.join(separador);
        }
        return arraystring;
    },

    removeDuplicados: function (array) {
        return array.filter((a, b) => array.indexOf(a) === b)
    },

    isNumeric: function (n) {
        return !isNaN(parseFloat(n)) && isFinite(n);
    },
    // Converte caracteres para zero e limita um numero a duas casas decimais
    cnum: function (num) {

        if (this.isNumeric(num) === false) {
            num = 0;
        } else {
            num = parseFloat(num);
        }
        return num;
    },
    replaceAll: function (txt, replace, with_this) {
        if (txt != null && txt != '') {
            if (with_this != null && replace != null) {
                while (txt.toString().indexOf(replace.toString()) > -1) {
                    txt = txt.toString().replace(replace.toString(), with_this.toString());
                }
            }
            return txt;
        }
        else {
            return '';
        }
    },
    ValueIsInFilter: function (listOfvalues, valueToFind) {
        if (valueToFind !== '' && valueToFind !== null) {
            if (listOfvalues.toString().toUpperCase().indexOf(valueToFind.toString().toUpperCase()) > -1) {
                return 1;
            }
            else {
                return 0;
            }
        }
        else {
            return 1;
        }
    },
    SetDecimais: function (valor, ndecimais) {
        if (cnum(valor) == 0) {
            valor = 0;
        }
        return valor.toLocaleString('pt-br', { minimumFractionDigits: ndecimais, maximumFractionDigits: ndecimais });
    }
}
//import axios from 'axios';
//export default {

//    //siteBackend: 'https://cinexms.d-next.it',
//    //siteBackend: 'http://localhost:64195'

//}

const servers = {
    dev: 'https://cinexms.d-next.it',
    production: 'https://cinexmsprod.d-next.it',
};

export function getServerUrl(serverType) {
    return servers[serverType] || servers.dev;
}
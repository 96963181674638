<template>

    <div :class="'my-5 col-sm-'+ blocksize +' alert alert-' + tpalert" v-if="show === 1" role="alert">
        <strong>Mensagem: </strong> {{operacao}}
    </div>

</template>
<script>

    export default {
        props: {
            tpalert: { type: String, required: false, default: 'primary' },
            show: { type: Number, required: false, default: 0 },
            operacao: { type: String, required: false, default: 'Manutenção de dados' },
            blocksize: { type: String, required: false, default: '12' },
        },

        data() {
            return {
                limitstemp: "",
                showFormLimits: 0,
                inputsizeselect: 12
            }
        },
        created: function () {

        },
        mounted: function () {

        },
        methods: {}
    }

</script>
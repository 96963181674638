<script>

    import pb from '@/js/ProideaBase.js';
    export default {

        props: {
            label: { type: String, required: false, default: 'Label' },
            itens: { type: Array, required: true },
            labelsize: { type: String, required: false, default: '12' },
            inputsize: { type: String, required: false, default: '12' },
            colsize: { type: String, required: false, default: 'sm' },
            blocksize: { type: String, required: true },
            disabled: { type: String, required: false, default: '0' },
            val: { type: String, required: false, default: '' },
            adicionalRowClass: { type: String, required: false, default: '' },
            limits: { type: String, required: false, default: '' },
        },

        data() {
            return {
                limitstemp: "",
                showFormLimits: 0,
                inputsizeselect: 12
            }
        },
        created: function () {
            if (this.itens.length == 0) {
                console.log('Zero itens em:' + this.label)
            }
            this.inputsizeselect = this.inputsize - 1;
            //---
        },
        mounted: function () {

            var arr = this.limits.split(';')
        },
        methods: {
            AbreFormLimitacoes: function () {
                try {
                    this.limitstemp = this.limits;
                    for (var i = 0; i < this.itens.length; i++) {
                        if (this.limitstemp.indexOf(";" + this.itens[i].cod + ";") > -1) {
                            this.itens[i].checked = true;
                        }
                        else {
                            this.itens[i].checked = false;
                        }
                    }
                    this.showFormLimits = 1;
                } catch (err) {
                    if ((String(err)).includes('Cannot read properties of undefined')) {
                        alert('Nenhum item cadastrado.')
                    } else {
                        alert(err);
                    }
                }
            },
            fechardetalhe: function () {
                this.showFormLimits = 0;
            },
            salvardetalhe: function () {
                this.showFormLimits = 0;
                return this.limitstemp;

            },
            salvaopcao: function (item) {
                var o = JSON.stringify(item);
                var usuario = 'Miguel';
                var vm = this;
                axios.get('https://cinexms.d-next.it/ws/altera.asmx/SaveCarOpc', {
                    params: { usuario: usuario, opcao: o },
                }).then((res) => {
                    console.log(res.data);
                    vm.loadopcs(item.codcar);//STATUS
                }).catch((error) => {
                    console.log(error);
                });
            },
            changeCheckedOption: function (item) {
                
                if (item.checked==false) {
                    // remover
                    this.limitstemp = pb.replaceAll(this.limitstemp, ";" + item.cod + ";", ";");
                }
                else {
                    // incluir
                    this.limitstemp = this.limitstemp + ";" + item.cod + ";";
                }
                this.limitstemp = pb.replaceAll(this.limitstemp, ";;", ";");
            }
        }
    }

</script>


<template>

    <div :class="'col-'+ colsize + '-' + blocksize + ' ' + adicionalRowClass">
        <label :class="'col-' + colsize + '-' + labelsize + ' avancoLabel'">{{label}}</label>
        <div :class="'col-' + colsize + '-' + inputsizeselect">
            <select class="form-control" :value="val" @input="$emit('update:val', $event.target.value)"
                    :disabled="disabled=='1'">
                <option v-for="item in itens" :value="item.cod">{{item.des}}</option>
            </select>
        </div>
        <div :class="'col-' + colsize + '-1'"></div>
        <div :class="'col-' + colsize + '-1'">
            <!--<img class="limitsImg" src="../../assets/limits.png" width="20" @click="AbreFormLimitacoes()" />-->
            <font-awesome-icon icon="sliders" @click="AbreFormLimitacoes()" />
        </div>
        <div :class="'col-' + colsize + '-1'"></div>
    </div>


        <div class="modal" v-if="showFormLimits=='1'" @close="showModal = false">
            <div class="modal-dialog modal-dialog-centered modal-lg rounded-3" role="document" >
                <div class="modal-content">
                    <div class="modal-header">
                        <h4>Opções disponíveis:</h4>
                        <button type="button" class="btn-close" aria-label="Close" @click.prevent.stop="fechardetalhe()">
                            <!--<span aria-hidden="true">&times;</span>-->
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="row bootstrap-table">
                            <div class="form-group customCheck form-check">
                                <ul id="listItens">
                                    <li v-for="item in itens">
                                        <input type="checkbox" class="form-check-input" @change="changeCheckedOption(item)" v-model="item.checked" :id="item.cod" />
                                        <label for="item.cod" class="form-check-label">{{ item.des }}</label>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div class="modal-footer grupo-btn p-3">
                        <button type="button" class="btn btn-salvar" @click="$emit('update:limits',this.salvardetalhe())">Salvar</button>
                    </div>


                </div>
            </div>


        </div>

</template>


<style>
    /*.modal{
        display:none;
    }*/
    .modal {
        display: block !important;
        opacity: 1 !important;
        background-color: rgba(0,0,0,.5);
    }
    /*.modalfadeIn {
        position: fixed;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        overflow: auto;
        padding: 0;
        margin: 0;
        background-color: rgba(0,0,0,.5);
    }*/
    h4{
        font-weight: bold;
    }
    .customCheck input {
        font-size: 20px;
        width: 20px;
        height: 20px;
    }
    .customCheck label {
        font-size: 20px;
        margin-left: 10px;
    }
    .btn-salvar {
        background-color: #c75036;
        color: white;
    }
    .btn-salvar:hover{
        border: 1px solid #c75036;
        color:#c75036;
    }
    svg {
        cursor: pointer;
    }
    .limitsImg {
        height: 40px;
        width: 40px;
        padding: 5px;
    }
    .close {
        position: absolute;
        float: right;
        right: 0px;
        top: 0px;
    }
    /*.avancoLabel{
        padding-left:25px;
    }*/
    /*.modal-dialog {
        top: 20%;
    }*/
    #listItens {
        list-style: none;
        margin-left: 50px;
        margin-top: 20px;
        column-count: 2;
    }
    #listItens li{
        margin-bottom: 20px;
    }
    /*.modal-dialog {
        background-color: white;
    }
    .modal-header {
        height: 30px;
        padding: 30px 20px;
    }*/

</style>
<template>
    <div class="app">
        <div v-if="this.$route.name==null" class="col-sm-12">
            <router-view></router-view>
        </div>
            <div v-if="this.$route.name!=null">                    
                    <sideBarMenu @sidebar-toggled="updateSidebarState" />
            </div>

            <div v-if="this.$route.name!=null" class="main" :class="{'sidebar-expanded': isSidebarExpanded}">
                    <router-view></router-view>
            </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                isSidebarExpanded: true,
            };
        },
        methods: {
            updateSidebarState(isExpanded) {
                this.isSidebarExpanded = isExpanded; // Atualiza o estado da barra lateral em App.vue
            },
        },
    }
</script>
<style>
    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        font-family: 'Rubik', sans-serif;
    }
    :root {
        --primary: #4ade80;
        --primary-alt: #22c55e;
        --grey: #64748b;
        --dark: #1e293b;
        --dark-alt: #334155;
        --light: #f1f5f9;
        --sidebar-width: 300px;
        --color-bt: #c75036;
        --color-bt-save: #22c55e;
        --color-bt-log: #1e293b;
        --color-bt-delete: #ff0000;
        --white: #fff;
        --body-color: #212529;
    }
    button {
        cursor: pointer;
        appearance: none;
        border: none;
        outline: none;
        background: none;
    }
    h1{
        margin:0 !important;
    }
    button.btnSave {
        background-color: var(--color-bt-save);
        color: var(--white);
    }
    button.btnSave:hover {
        background-color: transparent;
        border: 1px solid var(--color-bt-save);
        color: var(--color-bt-save)
    }
    .sidebar-expanded {
        margin-left: 300px;
        padding: 0 !important;
    }
    label {
        font-weight: bold;
    }
    tr{
        cursor:pointer;
    }
    .app {
        display: flex;
    }

    .app main {
        flex: 1 1 0;
    }

    .app .main {
        flex: 1 1 0;
        width: 100%;
        padding-left: 60px;
    }
    button.btnNew {
        background-color: var(--color-bt);
        color: white;
    }

    button.btnNew:hover {
        border: 1px solid var(--color-bt);
        color: var(--color-bt);
    }

    .app .sideicon {
        vertical-align: bottom;
    }

    .app section {
        padding: 1.8rem 2.5rem;
    }

    .app section.topo {
        background-color: #efefef;
    }

    .app h3 {
        margin: 0;
    }

    #body {
        padding: 15px;
    }

    #cabecalho {
        padding-top: 26px;
        padding-bottom: 32px;
        margin: 2px;
        margin-bottom:20px;
        text-align: left;
        border-bottom: 1px solid #808284;
    }

    #cabecalho h1 {
        margin: 0px;
        color: black !important;
        padding-left: 1%;
    }

    #app {
        font-family: "Rubik",Arial, Helvetica, sans-serif;
    }

    .row input:checked ~ .checkbox {
        background-color: #F15A22;
    }

        /*@font-face {
            font-family: "Geogrotesque";
            src: local("Geogrotesque"), url(./fonts/Geogrotesque/Geogrotesque-Medium.otf) format("truetype");
        }*/

/**** MOBILE ****/
    @media (max-width: 576px) {
        .app .main {
            padding-left: 45px !important;
        }
        .w-sm {
            width: 100% !important;
        }
        .app main {
            padding-left: 0;
        }
        .sidebar-expanded {
            margin: 0;
            padding: initial;
        }
    }
    /*** TABLE MOBILE ***/
    @media screen and (max-width: 600px) {
        table {
            border: 0;
        }

        table thead {
            border: none;
            clip: rect(0 0 0 0);
            height: 1px;
            margin: -1px;
            overflow: hidden;
            padding: 0;
            position: absolute;
            width: 1px;
        }

        table tr {
            border-bottom: 3px solid #ddd;
            display: block;
            /*margin-bottom: .625em;*/
        }

        table td {
            border-bottom: 1px solid #ddd;
            display: block;
            font-size: .8em;
            text-align: right;
            padding: 1rem 0.5rem !important;
        }

        table td::before {
            /*
            * aria-label has no advantage, it won't be read inside a table
            content: attr(aria-label);
            */
            content: attr(data-label);
            float: left;
            font-weight: bold;
            text-transform: uppercase;
        }

        table td:last-child {
            border-bottom: 0;
        }
        .table > tbody > tr:nth-of-type(odd) > td,
        .table > tbody > tr:nth-of-type(odd) > th,
        .table > tbody > tr:nth-of-type(odd) > tr {
            background-color: var(--bs-table-striped-bg);
            color: var(--bs-table-striped-color);
        }
    }
    /*@media (max-width: 1024px) {
        .app main {
            padding-left: 4rem;
        }
    }*/



</style>

<script>
    
    //defineEmits(['update:checked'])
    export default {
        props: {
            label: { type: String, required: false, default: 'Label' },
            labelsize: { type: String, required: false, default: '12' },
            inputsize: { type: String, required: false, default: '12' },
            colsize: { type: String, required: false, default: 'sm' },
            blocksize: { type: String, required: true, default: '4' },
            disabled: { type: String, required: false, default: '0' },
            checked: { type: String, required: false, default: 'false' },
        },
        created: function () {
            if (this.checked) {
                //console.log('checked')
            }
        },
    }


</script>

<template>
    <div :class="'col-'+ colsize + '-' + blocksize " >
        <label :class="'col-' + colsize + '-' + labelsize + ' control-label'" v-if="label!=''">{{label}}</label>
        <div :class="'col-' + colsize + '-' + inputsize + ' text-right align-items-right justify-content-right '">
            <input type="checkbox" class="checkbox customCheck2" :checked="checked"  :disabled="disabled=='1'"
                   @change="$emit('update:checked', $event.target.checked)"/>
                   
        </div>
    </div>
</template>
<style>
    .checkbox{
        float: right;
    }
    .customCheck2 {
        font-size: 20px;
        width: 20px;
        height: 20px;
    }

</style>
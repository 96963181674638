<template>
    <div>
        <div class="container">
            <div class="d-flex gap-4 justify-content-end btPosition">
                <!-- configuracoes gerais -->

                <button v-if="currentPage === 1 && !isMobile" @click="mostraLogConf" type="button" class="btn btnLog"><font-awesome-icon icon="fa-solid fa-file-lines" /> Log</button>
                <button v-if="currentPage === 1 && !isMobile" @click="salvarConfGeral" type="button" class="btn btnSave"><font-awesome-icon icon="fa-solid fa-floppy-disk" /> Salvar</button>

                <!-- mob configuracoes gerais -->
                <div v-if="currentPage === 1 && isMobile">
                    <button @click="toggleInfo" class="btn btnMore shadow-2">+</button>
                    <div class="d-grid gap-3" id="infoDiv" v-if="showInfo">
                        <button @click="mostraLogConf" type="button" class="btn btnLog"><font-awesome-icon icon="fa-solid fa-file-lines" /> Log</button>
                        <button @click="salvarConfGeral" type="button" class="btn btnSave"><font-awesome-icon icon="fa-solid fa-floppy-disk" /> Salvar</button>
                    </div>
                </div>

                <!-- tabelas -->
                <button v-if="currentPage === 2 && !isMobile" @click="mostraLogTabela" type="button" class="btn btnLog"><font-awesome-icon icon="fa-solid fa-file-lines" /> Log</button>
                <button v-if="currentPage === 2 && !isMobile" @click="salvarTabela" type="button" class="btn btnSave"><font-awesome-icon icon="fa-solid fa-floppy-disk" /> Salvar</button>
                <div v-if="currentPage === 2 && !isMobile" style="border-left: 1px solid black"></div>
                <button v-if="currentPage === 2 && !isMobile" @click="deletarTabela" type="button" class="btn btnDelete"><font-awesome-icon icon="fa-solid fa-trash-can" /> Excluir</button>

                <!-- mob tabelas -->
                <div v-if="currentPage === 2 && isMobile">
                    <button @click="toggleInfo" class="btn btnMore shadow-2">+</button>
                    <div class="d-grid gap-3" id="infoDiv" v-if="showInfo">
                        <button @click="mostraLogTabela" type="button" class="btn btnLog"><font-awesome-icon icon="fa-solid fa-file-lines" /> Log</button>
                        <button @click="salvarTabela" type="button" class="btn btnSave"><font-awesome-icon icon="fa-solid fa-floppy-disk" /> Salvar</button>
                        <button @click="deletarTabela" type="button" class="btn btnDelete"><font-awesome-icon icon="fa-solid fa-trash-can" /> Excluir</button>

                    </div>
                </div>

                <!-- lojas -->
                <button v-if="currentPage === 3 && !isMobile" @click="mostraLogLoja" type="button" class="btn btnLog"><font-awesome-icon icon="fa-solid fa-file-lines" /> Log</button>
                <button v-if="currentPage === 3 && !isMobile" @click="salvarLoja" type="button" class="btn btnSave"><font-awesome-icon icon="fa-solid fa-floppy-disk" /> Salvar</button>

                <!-- mob lojas -->
                <div v-if="currentPage === 3 && isMobile">
                    <button @click="toggleInfo" class="btn btnMore shadow-2">+</button>
                    <div class="d-grid gap-3" id="infoDiv" v-if="showInfo">
                        <button @click="mostraLogLoja" type="button" class="btn btnLog"><font-awesome-icon icon="fa-solid fa-file-lines" /> Log</button>
                        <button @click="salvarLoja" type="button" class="btn btnSave"><font-awesome-icon icon="fa-solid fa-floppy-disk" /> Salvar</button>
                    </div>
                </div>

                <!-- itens coeficiente -->                
                <button v-if="currentPage === 4 && !isMobile" @click="salvarItens" type="button" class="btn btnSave"><font-awesome-icon icon="fa-solid fa-floppy-disk" /> Salvar</button>

                <!-- mob itens coeficiente -->
                <div v-if="currentPage === 4 && isMobile">
                    <button @click="toggleInfo" class="btn btnMore shadow-2">+</button>
                    <div class="d-grid gap-3" id="infoDiv" v-if="showInfo">                       
                        <button @click="salvarItens" type="button" class="btn btnSave"><font-awesome-icon icon="fa-solid fa-floppy-disk" /> Salvar</button>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['currentPage'],
        data() {
            return {
                showInfo: false, // Inicialmente, as informa��es s�o exibidas
            };
        },
        computed: {
            isMobile() {
                return window.innerWidth <= 768; // Altere o limite conforme necess�rio
            },
        },
        methods: {
            salvarItens(){
                this.$emit('salvar-coef');
            },
            salvarConfGeral() {
                this.$emit('salvar-conf');
            },
            mostraLogConf() {
                this.$emit('mostra-log-conf');
            },
            salvarTabela() {
                this.$emit('salvar-tabela');
            },
            deletarTabela() {
                this.$emit('deletar-tabela');
            },
            salvarLoja() {
                this.$emit('salvar-loja');
            },
            mostrarLogLoja() {
                this.$emit('mostrar-log-loja');
            },
            toggleInfo() {
                this.showInfo = !this.showInfo; // Inverte o estado de exibi��o das informa��es
            },
        },
    }
</script>

<style scoped>
    /*button {
        display: block;
        margin: 10px auto;
        padding: 10px 20px;
        background-color: #3498db;
        color: #fff;
        border: none;
        border-radius: 5px;
        cursor: pointer;
    }*/
    .btn.btnMore {
        position: fixed;
        right: 10px;
        padding: 5px 15px;
        background-color: #b3b3b3;
        color: #000;
        border-radius: 25px;
        font-size: 1.333rem;
        top: 22px;
    }
    #infoDiv {
        display: block;
        margin: 40px 0px;
        padding: 10px;
        background-color: #e8e8e8;
        border: 1px solid black;
        text-align: center;
        border-radius: 5px;
        color: white;
    }
    .btn {
        padding: .4rem 1.5rem;
    }
    .btPosition {
        position: absolute;
        right: 0;
        padding-right: 2rem;
        padding-top: 0.5rem;
    }

    button.bt {
        background-color: black;
        color: white;
        align-items: center;
    }    
    button.btnLog {
        background-color: var(--color-bt-log);
        color: var(--white);
    }
    button.btnLog:hover {
        background-color: transparent;
        border: 1px solid var(--color-bt-log);
        color: var(--color-bt-log)
    }
    button.btnDelete {
        background-color: var(--color-bt-delete);
        color: var(--white);
    }
    button.btnDelete:hover {
        background-color: transparent;
        border: 1px solid var(--color-bt-delete);
        color: var(--color-bt-delete)
    }
    button .material-icons {
        margin-right: 0.5rem;
    }

    @media (max-width: 576px) {
        .btPosition {
            padding-top:0;
        }
    }
    /*vertical-align: middle;*/
    /*https://www.npmjs.com/package/material-icons*/
</style>